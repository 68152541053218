.surf-courses {
    background-image: url("../../../public/img/pages/background-coach.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    .red-title {
        color: #be433e;
    }
    .red-description {
        color: #912330;
    }
    .blue-text {
        color: #22779b;
    }

    .block-course {
        margin: 98px 20px 24px 20px;
        background: #fffdec;
        text-align: center;
        padding-bottom: 24px;
        img {
            margin-top: -64px;
        }
        .description-global {
            padding: 0 10px;
        }
    }

    .block-prices {
        margin: 24px 20px 24px 20px;
        .title-global {
            margin-bottom: 24px;
            .sub-title {
                font-size: 20px;
                line-height: 26px;
            }
        }
        .prices {
            margin-top: 3px;
            background: #ffd9dd;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 65px;
            cursor: pointer;
            .time {
                background: linear-gradient(90deg, #912330 0%, #db4b5c 95.35%);
                border-radius: 20px;
                color: white;
                font-weight: 700;
                font-size: 50px;
                line-height: 65px;
                text-align: center;
                width: 20%;
                min-height: 65px;
            }
            .duration {
                color: #912330;
                width: 60%;
                padding: 0 10px;
            }
            .price {
                background: linear-gradient(270deg, #912330 0%, #db4b5c 100%);
                border-radius: 20px;
                width: 20%;
            }
        }
        .prices:hover {
            opacity: 0.8;
        }
        .block-details {
            cursor: pointer;
        }
        .block-details:hover {
            opacity: 0.8;
        }
    }

    .one-person {
        display: none;
    }
    .two-persons {
        display: none;
    }
    .three-persons {
        display: none;
    }
    .four-persons {
        display: none;
    }

    .reserve {
        text-align: center;
        margin-bottom: 48px;
        p {
            margin-top: 5px;
            font-size: 11px;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .surf-courses {
        background-image: url("../../../public/img/pages/background-mobile-coach.webp");
        .title-global {
            font-size: 25px;
            line-height: 25px;
        }
        .block-prices {
            .title-global {
                .sub-title {
                    font-size: 16px;
                    line-height: 21px;
                }
            }
            .prices {
                min-height: 42px;
                .time {
                    color: white;
                    width: 30%;
                    font-size: 32px;
                    line-height: 42px;
                    min-height: 42px;
                }
                .duration {
                    width: 50%;
                    padding: 0 7px;
                }
                .price {
                    color: #ffffff;
                    width: 20%;
                }
            }
            .block-person {
                width: 70%;
                padding: 10px 20px;
                img {
                    width: 22px;
                }
                .title-price {
                    font-size: 32px;
                    line-height: 42px;
                }
            }
            .block-details {
                .duration {
                    padding: 0px 20px;
                }
            }
        }

        .duration {
            font-size: 16px;
            line-height: 21px;
            min-height: 42px;
        }
        .price {
            font-size: 24px;
            line-height: 31px;
            min-height: 42px;
        }
    }
}
@media only screen and (max-width: 400px) {
    .surf-courses {
        .title-global {
            font-size: 21px;
            line-height: 21px;
        }
        .block-prices {
            .title-global {
                .sub-title {
                    font-size: 11px;
                    line-height: 16px;
                }
            }
            .prices {
                min-height: 33px;
                .time {
                    color: white;
                    font-size: 23px;
                    line-height: 33px;
                    width: 35%;
                    min-height: 33px;
                }
                .duration {
                    width: 40%;
                    padding: 0 3px;
                }
                .price {
                    color: #ffffff;
                    width: 25%;
                }
            }
            .block-person {
                width: 80%;
                img {
                    width: 15px;
                }
                .title-price {
                    font-size: 19px;
                    line-height: 25px;
                }
            }
            .block-details {
                .duration {
                    width: 70%;
                }
                .price {
                    width: 30%;
                }
            }
        }
        .duration {
            font-size: 11px;
            line-height: 14px;
            min-height: 33px;
        }
        .price {
            font-size: 19px;
            line-height: 24px;
            min-height: 33px;
        }
    }
}
