
    .pop-up {
        border: 2px #a5e0f9 solid;
        border-radius: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(34, 119, 155, 0.9);
        padding: 24px 24px;
        .content-res {
            text-align: center;
            h2 {
                font-size: 21px;
                line-height: 21px;
            }
            h3 {
                margin-top: 6px;
                font-size: 13px;
                color: #ffffffc9;
            }
            .choices {
                margin-top: 12px;
                display: flex;
                justify-content: center;
                button {
                    border: white solid 1px;
                    background: none;
                    padding: 5px 10px;
                    margin: 2px;
                    font-size: 15px;
                    color: white;
                    border-radius: 15px;
                    cursor: pointer;
                }
                button:hover {
                    background: rgba(255, 255, 255, 0.2);
                }
            }
        }
    }