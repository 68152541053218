.card-opinion {
    background: #fffdec;
    border-radius: 20px;
    text-align: center;
    padding-bottom: 24px;
    .img-card {
        width: 100%;
        border-radius: 20px 20px 0 0;
    }
    .infos {
        min-height: 220px;
        .name {
            font-size: 30px;
            line-height: 39px;
            color: #22779b;
            display: flex;
            justify-content: center;
            img {
                margin-left: 10px;
            }
        }
        .comment {
            margin-top: 12px;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #3d95ba;
            padding: 0 20px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .card-opinion {
        .infos {
            min-height: initial;
            .name {
                font-size: 27px;
                line-height: 34px;
                color: #22779b;
                display: flex;
                justify-content: center;
            }
            .comment {
                font-size: 16px;
                line-height: 21px;
            }
        }
    }
}
