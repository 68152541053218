.camp {
    background-image: url("../../../public/img/pages/background.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    .background-color {
        background: #094964;
    }
    .container {
        padding: 48px 10px;
        .reverse-columns {
            flex-direction: row-reverse;
        }

        .justify{
            text-align: justify;
        }
        .block {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            .block-img {
                width: 50%;
                padding: 10px 10px 7px 10px;
                border-radius: 30px;
                border: white 1px solid;
                margin: 0 10px;
                img {
                    border-radius: 20px;
                    width: 100%;
                }
            }
            .block-description {
                width: 50%;
                margin: 0 10px;
                .title-global {
                    margin-bottom: 12px;
                }
                .description-global {
                    margin-top: 5px;
                    display: flex;
                    align-items: center;
                    color: white;
                    img {
                        margin-right: 10px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        .div-btn {
            display: flex;
            justify-content: center;
            button {
                border: white solid 1px;
                color: white;
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .camp {
        background-image: url("../../../public/img/pages/background-mobile.webp");
        .container {
            .block {
                display: block;
                .block-img {
                    width: 95%;
                    margin: 0 2.5%;
                    margin-bottom: 24px;
                }
                .block-description {
                    width: 95%;
                    margin: 0 2.5%;
                }
            }
        }
    }
}
