.legal-notice {
    background: #094964;

    .content-description-cvg {
        margin-top: 6px;
        font-style: italic;
    }
    .title-cvg {
        font-size: 60px;
        line-height: 60px;
    }

    .container {
        padding: 24px 20px 48px 20px;
        .title-global {
            margin-top: 24px;
        }
        .sub-title {
            margin-top: 12px;
            font-size: 23px;
            padding-left: 2px;
        }
        .description-global {
            color: white;
            padding-left: 3px;
            a {
                text-decoration: none;
                color: white;
            }
            a:hover {
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}
