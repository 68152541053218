.gallery {
    background-image: url("../../../public/img/pages/background.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    .color-back {
        background: #094964;
        .container {
            padding: 48px 0px;

            .img-square {
                width: 31%;
                margin: 1% 1%;
            }
            .img-rect {
                width: 64%;
                margin: 1% 1%;
            }
            .div-btn-more {
                display: flex;
                align-items: center;
                justify-content: center;
                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        margin-left: 10px;
                    }
                }
            }
            .block-none {
                display: none;
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .gallery {
        background-image: url("../../../public/img/pages/background-mobile.webp");
        .color-back {
            .container {
                .img-square {
                    width: 48%;
                    margin: 1% 1%;
                }
                .img-rect {
                    width: 98%;
                    margin: 1% 1%;
                }
            }
        }
    }
}
