.trip {
    background-image: url("../../../public/img/pages/background.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    .container {
        padding: 24px 20px 24px 20px;
        .block-reverse {
            flex-direction: row-reverse;
        }
        .block-travel {
            margin: 48px 0 24px 0;
            display: flex;
            align-items: center;
            img {
                width: 50%;
                margin: 0 10px;
                border-radius: 20px;
            }
            .block-description {
                width: 50%;
                margin: 0 10px;
                .title-blue-global{
                    margin-bottom: 12px;
                }
                .description-global {
                    margin-top: 0px;
                    text-align: justify;
                }
            }
        }

        .more {
            text-align: center;
            margin-bottom: 48px;
        }
    }
}

@media only screen and (max-width: 580px) {
    .trip {
        background-image: url("../../../public/img/pages/background-mobile.webp");
        .container {
            .block-reverse {
                flex-direction: initial;
            }
            .block-travel {
                display: block;
                img {
                    width: 95%;
                    margin: 0 2.5%;
                }
                .block-description {
                    width: 95%;
                    margin: 0 2.5%;
                    text-align: center;
                    .title-blue-global{
                        margin-top: 12px;
                    }
                    .description-global {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
