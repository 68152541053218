.coach {
    background-image: url("../../../public/img/pages/background-coach.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    .blocks {
        display: flex;
        margin: 48px auto;
        align-items: center;
        text-align: justify;
        .img-coach {
            margin-right: 24px;
            img {
                border-radius: 20px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .coach {
        background-image: url("../../../public/img/pages/background-mobile-coach.webp");
        .blocks {
            display: block;
            margin: 0;
            text-align: center;
            .img-coach {
                margin: 0px;
                img {
                    border-radius: 0px;
                    width: 100%;
                }
            }
            .description-coach {
                padding: 24px 24px 48px 24px;
            }
        }
    }
}
