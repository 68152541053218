.home {
    background-image: url("../../../public/img/pages/background.webp");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    .img-background {
        .titles {
            .title {
                font-weight: 500;
                font-size: 120px;
                line-height: 120px;

                color: #d3d7dd;
                span {
                    color: white;
                }
            }
            .sub-title {
                font-weight: 500;
                font-size: 90px;
                line-height: 120px;

                color: #d3d7dd;
            }
            .description {
                padding-left: 5px;
                font-style: italic;
                font-weight: 500;
                font-size: 24px;
                line-height: 31px;
                color: #ffffff;
            }
        }
    }
    .surf-school {
        padding: 48px 20px;
        .title {
            text-align: center;
        }
        .description {
            text-align: center;
        }
    }

    .coach-home {
        display: flex;
        background-image: url("../../../public/img/pages/home/coach-cut.webp");
        background-size: 65%;
        background-repeat: no-repeat;
        .container {
            .block-right {
                width: 40%;
                margin-left: 60%;
                padding: 100px 0;
                .description {
                    text-align: justify;
                }
            }
        }
        .coach-img {
            display: none;
        }
    }

    .courses {
        padding: 48px 10px;
        .title {
            text-align: center;
        }
        .cards {
            width: 100%;
            display: flex;
            .card {
                width: 33%;
                margin: 0 1%;
            }
        }
    }
    .surf-stuffs {
        background: #3d95ba;
        padding: 28px 0px;
        .surf-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 20px;
            .img-surf {
                width: 48%;
                border: white 1px solid;
                border-radius: 30px;
                padding: 10px 10px 3px 10px;
                img {
                    width: 100%;
                    border-radius: 20px;
                }
            }
            .block {
                width: 48%;
                text-align: justify;

                button {
                    border: white 1px solid;
                    color: white;
                }
            }
        }
        .surf-block-reverse {
            flex-direction: row-reverse;
        }
    }
    .gallery-home {
        width: 100%;
        padding: 48px 10px;
        .pictures {
            margin-top: 24px;
            width: 100%;
            img {
                float: left;
                width: 31%;
                margin: 1%;
            }
        }
        .more {
            text-align: center;
        }
    }

    iframe {
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .home {
        background-image: url("../../../public/img/pages/background-mobile.webp");
        .img-background {
            padding-left: 0px;
            .titles {
                text-align: center;
                width: 100%;
                .title {
                    font-size: 60px;
                    line-height: 60px;
                }
                .sub-title {
                    font-size: 35px;
                    line-height: 46px;
                }
                .description {
                    padding-left: 0px;
                    font-size: 16px;
                    line-height: 21px;
                }
            }
        }

        .coach-home {
            display: block;
            background: none;
            .container {
                padding: 24px 20px 48px 20px;
                .block-right {
                    width: 100%;
                    float: initial;
                    margin-left: initial;
                    padding: 0 0;
                    .title {
                        text-align: center;
                    }
                    .description {
                        text-align: center;
                    }
                    .btn-more {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
            .coach-img {
                display: block;
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }

        .courses {
            padding: 0px 20px 48px 20px;

            .cards {
                display: block;
                .card {
                    width: 100%;
                    margin: 0;
                }
            }
        }

        .surf-stuffs {
            padding: 28px 0px;
            .surf-block {
                display: block;
                padding: 20px 20px;
                .img-surf {
                    width: 100%;
                    img {
                    }
                }
                .block {
                    width: 100%;
                    .title-global {
                        margin-top: 24px;
                        margin-bottom: 12px;
                    }
                }
            }
            .surf-block-reverse {
                flex-direction: initial;
            }
        }
        .gallery-home {
            width: 100%;
            padding: 48px 20px;
            .pictures {
                margin-top: 24px;
                width: 100%;
                img {
                    float: left;
                    width: 48%;
                    margin: 1%;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .home {
        .img-background {
            .titles {
                .title {
                    font-size: 40px;
                    line-height: 40px;
                }
                .sub-title {
                    font-size: 25px;
                    line-height: 36px;
                }
                .description {
                    font-size: 13px;
                    line-height: 15px;
                }
            }
        }
    }
}
