$font-1: sans-serif;
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    a{
        cursor: pointer;
    }
    input,
    textarea,
    select,
    option {
        font-family: $font-1;
    }
    height: 1vh;
    font-family: $font-1;

    font-style: normal;
    color: #ffffff;
    font-weight: 400;

    button {
        font-family: $font-1;
        font-weight: 500;
    }

    .container {
        max-width: 1240px;
        margin: auto;
    }

    .img-background {
        height: 100vh;
        background-color: rgba(34, 119, 155, 0.8);
        display: flex;
        align-items: center;
        padding-left: 70px;
    }

    .main-title {
        width: 60%;
        .title {
            font-weight: 500;
            font-size: 120px;
            line-height: 120px;
            color: #ffffff;
        }
        .description {
            margin-top: 24px;
            font-size: 20px;
            line-height: 26px;
            text-align: justify;
            width: 80%;
        }
    }

    .title-global,
    .title-blue-global {
        font-size: 60px;
        line-height: 78px;
    }
    .title-blue-global {
        color: #22779b;
    }

    .sub-title-blue-global {
        color: #22779b;
        font-size: 25px;
        line-height: 33px;
    }

    .description-global {
        margin-top: 12px;
        font-size: 20px;
        line-height: 26px;
        color: #646464;
    }

    @media only screen and (max-width: 1200px) {
        .img-background {
            padding-left: 0px;
            .main-title {
                text-align: center;
                width: 96%;
                margin: 0 2%;
                .title {
                    font-size: 60px;
                    line-height: 60px;
                }
                .description {
                    margin: 24px auto 0 auto;
                    font-size: 16px;
                    line-height: 21px;
                    text-align: center;
                }
            }
        }

        .description-global {
            font-size: 16px;
            line-height: 21px;
        }
        .title-global,
        .title-blue-global {
            font-size: 48px;
            line-height: 48px;
        }

        .sub-title-blue-global {
            font-size: 22px;
            line-height: 29px;
        }
    }

    @media only screen and (max-width: 400px) {
        .img-background {
            .main-title {
                .title {
                    font-size: 40px;
                    line-height: 40px;
                }
                .description {
                    margin: 12px auto 0 auto;
                    font-size: 11px;
                    line-height: 16px;
                }
            }
        }

        .description-simple-global {
            font-size: 13px;
            line-height: 15px;
        }
        .title-global,
        .title-blue-global {
            font-size: 36px;
            line-height: 36px;
        }

        .sub-title-blue-global {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .text-center {
        text-align: center;
    }

    /** BUTTONS **/

    .btn-blue,
    .btn-transparent {
        margin-top: 24px;
        border-radius: 25px;
        padding: 10px 20px;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        cursor: pointer;
    }

    .btn-blue {
        background: #3d95ba;
        border: none;
        color: #ffffff;
    }
    .btn-blue:hover {
        background: hsla(198, 51%, 48%, 0.8);
    }

    .btn-blue-disabled {
        cursor: not-allowed;
        background: hsla(198, 51%, 48%, 0.8);
    }

    .btn-transparent {
        background: none;
    }

    .btn-transparent:hover {
        background: rgba(255, 255, 255, 0.2);
    }

    @media only screen and (max-width: 1200px) {
        .btn-blue,
        .btn-transparent {
            font-size: 16px;
            line-height: 21px;
        }
    }

    .opinions {
        width: 100%;
        padding: 48px 20px;
        .opinions-card {
            width: 100%;
            display: flex;
            margin-top: 24px;
            .card {
                width: 31%;
                margin: 0 1%;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .opinions {
            .opinions-card {
                display: block;
                .card {
                    width: 100%;
                    margin: 12px 0;
                }
            }
        }
    }

    .social-networks-main-background {
        position: absolute;
        right: 50px;
        bottom: 50px;
        div {
            border: white 1px solid;
            border-radius: 23px;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            cursor: pointer;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 20px;
                }
            }
        }
        div:hover {
            background-color: hsla(0, 0%, 100%, 0.2);
        }
    }

    @media only screen and (max-width: 1200px) {
        .social-networks-main-background {
            display: none;
        }
    }

    .block-person {
        background: linear-gradient(90deg, #22779b 0%, #3c9ac2 95.35%);
        border-radius: 20px 20px 0px 0px;
        display: flex;
        justify-content: space-between;
        width: fit-content;
        align-items: center;
        padding: 10px 30px;
        width: 60%;
        cursor: pointer;
        .title-price {
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;

            color: #ffffff;
        }
    }
    .block-person-close {
        border-radius: 20px 20px 20px 0px;
        margin-bottom: 12px;
    }
    .block-person:hover {
        background: linear-gradient(90deg, #3390b8 0%, #2884ab 95.35%);
    }
    .block-details {
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        margin-top: 3px;
        .duration {
            color: #094964;
            width: 80%;
            padding: 0px 30px;
        }
        .price {
            background: linear-gradient(270deg, #0b678e 0%, #54afd5 100%);
            border-radius: 20px;
            width: 20%;
        }
    }
    .last-item {
        margin-bottom: 12px;
        border-radius: 0px 20px 20px 20px !important;
    }
    .light-blue {
        background: #ccf5f7;
    }
    .middle-blue {
        background: #a5e0f9;
    }

    @media only screen and (max-width: 1200px) {
        .block-person {
            width: 70%;
            padding: 10px 20px;
            img {
                width: 22px;
            }
            .title-price {
                font-size: 32px;
                line-height: 42px;
            }
        }
        .block-details {
            .duration {
                padding: 0px 20px;
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .block-person {
            width: 80%;
            img {
                width: 15px;
            }
            .title-price {
                font-size: 19px;
                line-height: 25px;
            }
        }
        .block-details {
            .duration {
                width: 70%;
            }
            .price {
                width: 30%;
            }
        }
    }

    .duration {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        min-height: 65px;
        display: flex;
        align-items: center;
    }
    .price {
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        min-height: 65px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 1200px) {
        .duration {
            font-size: 16px;
            line-height: 21px;
            min-height: 42px;
        }
        .price {
            font-size: 24px;
            line-height: 31px;
            min-height: 42px;
        }
    }

    @media only screen and (max-width: 400px) {
        .duration {
            font-size: 11px;
            line-height: 14px;
            min-height: 33px;
        }
        .price {
            font-size: 19px;
            line-height: 24px;
            min-height: 33px;
        }
    }

    .contacts {
        background-image: url("../../public/img/pages/background-coach.webp");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
    }

    @media only screen and (max-width: 1080px) {
        .contacts {
            background-image: url("../../public/img/pages/background-mobile-coach.webp");
        }
    }
}
